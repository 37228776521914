import React, {useState, useEffect} from 'react';



import {LanguageContext} from './LanguageProvider'
import Navbar from './navbar'
import Footer from './footer'


export default function ChildLayout(props){

  
    let [context, setContext] =useState("en")
        
    useEffect(() => {
        if (localStorage.getItem('language')) {
          setContext(localStorage.getItem('language'))
        }
      }, [])
     

let change=(event)=>{
    event.preventDefault()
     let newContext =   event.target.name
     setContext(event.target.name)
     window.localStorage.setItem('language', event.target.name);
}


console.log(props.mainHeading)
let x =(
    <LanguageContext.Provider value ={context}>
    <Navbar change = {change}></Navbar>
    {props.children}
    <Footer></Footer>
    </LanguageContext.Provider>
 )


 return x
       
}