import React, { useContext, useState } from 'react';
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from 'react-markdown'


import DateReader from '../../components/dateReader'
import ChildLayout from '../../components/childPageLayout'
import { LanguageContext } from '../../components/LanguageProvider'


export default function FullEvent({ data }) {
  let essentials = data.allCmbNewsJson.edges[0].node
let genericItems = data.allCmbPagesJson.edges[0].node
  return <ChildLayout>
    <main className={"newsMain"}>
      <NewsBody data={essentials} generics={genericItems} />
    </main>
  </ChildLayout>
}


function NewsBody({ data }) {
  let context = useContext(LanguageContext)

  let image = getImage(data.mainImage)
  let x = (
    <article>
      <DateReader date={data.date} />
      <ReactMarkdown allowedElements={"h1"}>{data[context].eventMain}</ReactMarkdown>
      <section class="newsOverview">
        <GatsbyImage className={"newsMainImage"} image={image} />
        <ReactMarkdown allowedElements={"p"}>{data[context].eventMain}</ReactMarkdown>
      </section>
    </article>

  )

  return x
}


export const query = graphql`
 query($id:String) {allCmbPagesJson{
  edges{         
  node{
    genericItems{
      en{
        seeMore
      }
      hr{
        seeMore
      }
    }
    eventsPage{
              en{
                  header
                  nextEvent
                  futureEvents
                  pastEvents
              }
              hr {
                  header
                  nextEvent
                  futureEvents
                  pastEvents
              }
          }
      }
  }
}
   
  
  
  allCmbNewsJson(filter:{id:{eq:$id}}){
 edges{         
    node{
      id
      date
      jsonId
      en {
        eventMain
      }
      hr {
        eventMain
      }
      mainImage {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}
}
`






